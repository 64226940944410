<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2021-12-03 12:58:21
 * @Description: 线下码
-->
<template>
    <div class="ant-card">
        <div class="ant-card-body">
            <div class="head-box">
                <div class="table-operator">
                    <a-button icon="plus" type="primary" @click="showModal" v-show="isShowAdd">
                        创建线下码
                    </a-button>
                    <!-- <a-form
                        layout="inline"
                        :model="form"
                        @submit="handleSearch"
                        @submit.native.prevent
                    >
                        <a-form-model-item>
                            <a-input v-model="form.agentUserId" placeholder="请输入代理">
                                <a-icon
                                    slot="prefix"
                                    type="search"
                                    style="color: rgba(0, 0, 0, 0.25)"
                                />
                            </a-input>
                        </a-form-model-item>
                        <a-form-model-item>
                            <a-button icon="search" type="primary" html-type="submit">搜索</a-button>
                        </a-form-model-item>
                    </a-form> -->
                </div>
            </div>
            <div class="content-box">
                <a-table
                    :columns="columns"
                    :data-source="tableList"
                    :pagination="false"
                >
                    <span slot="action" slot-scope="text, record">
                        <a
                            class="btn-record"
                            @click="handleRecord(record)"
                        >使用记录</a>
                    </span>
                </a-table>
            </div>
        </div>
        <a-modal
            title="生成线下码"
            width="44%"
            :visible="modalVisible"
            okText="确定"
            cancelText="取消"
            @ok="ok"
            @cancel="cancel"
        >
            <a-form layout="inline">
                <a-form-model-item label="填写邀请码数量">
                    <a-input-number
                        :min="0"
                        :max="10000"
                        @change="handleChange"
                    ></a-input-number>
                </a-form-model-item>
            </a-form>
        </a-modal>
        <MyPagination :count="count" @showSizeChangeFn="showSizeChangeFn" />
    </div>
</template>

<script>
import {
    MemberInvitationCodeListApi,
    MemberInvitationCodeSaveApi
} from '@/request/api/marketingCenter';
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn } from "@/utils/tools";


export default {
    components: {
        MyPagination,
    },
    data() {
        return {
            isShowAdd: true, // 是否显示新增的按钮
            modalVisible: false,
            agentUserId: 0,
            form: {},
            columns: [
                {
                    title: "邀请码",
                    dataIndex: "uniqueId",
                    key: "uniqueId",
                },
                {
                    title: "领取会员",
                    dataIndex: "toUserNickName",
                    key: "toUserNickName",
                },
                {
                    title: "领取会员账号",
                    dataIndex: "toUserName",
                    key: "toUserName"
                },
                {
                    title: "领取状态",
                    dataIndex: "statusText",
                    key: "statusText"
                },
                {
                    title: "领取时间",
                    dataIndex: "receiveTime",
                    key: "receiveTime",
                    sorter: (a, b) => {
                        let aTime = new Date(a.receiveTime).getTime();
                        let bTime = new Date(b.receiveTime).getTime();
                        return aTime - bTime;
                    }
                }
            ],
            count: 0,
            searchForm: {
                pageNo: 1,
                pageSize: 20
                // startTime: "",
                // endTime: "",
                // title: "",
                // type: ""
            },
            tableList: [],
            tableLoading: false,
        }
    },
    created() {
        // 权限验证
        if (codeFn("/admin/dealer/invitation-code/generateDealerInvitationCode")) {
            this.isShowAdd = true;
        }
        const {agentUserId} = this.$route.query;
        if (agentUserId) {
            this.agentUserId = agentUserId;
        }
        this.getInviteCodeList();
    },
    methods: {
        // 线下码列表数据
        getInviteCodeList() {
            this.tableLoading = true;
            const params = {
                ...this.searchForm,
                agentUserId: this.agentUserId
            };
            // const params = `agentUserId=${this.agentUserId}`;
            MemberInvitationCodeListApi(params).then((res) => {
                if (res.code == 200) {
                    this.count = res.data.count;
                    let newData = res.data.data;
                    newData.forEach((item) => {
                        item.key = item.uniqueId;
                        item.toUserName = item.toUserName || "--";
                        item.toUserNickName = item.toUserNickName || "--";
                        item.receiveTime = item.receiveTime || "--";
                    });
                    this.tableList = newData;
                }
                this.tableLoading = false;
            });
        },
        // 分页切换页数
        showSizeChangeFn(pageNo, pageSize) {
            this.searchForm.pageNo = pageNo;
            this.searchForm.pageSize = pageSize;
            this.getInviteCodeList();
        },
        handleSearch() {},
        ok() {
            this.createMemberCode();
        },
        cancel() {
            this.hideModel();
        },
        handleChange(value) {
            this.createMemberNumber = value;
        },
        showModal() {
            this.modalVisible = true;
        },
        hideModel() {
            this.modalVisible = false;
        },
        createMemberCode() {
            const params = `quantity=${this.createMemberNumber}`;
            MemberInvitationCodeSaveApi(params).then(res => {
                if (res.code === 200) {
                  this.$message.success("新增成功");
                  this.getInviteCodeList();
                  this.hideModel();
                }
            });
        },
        // 使用记录
        handleRecord(record) {
            let recordCodeData = {
                orderNo: record.orderNo,
                title: record.title,
                startTime: record.startTime,
                endTime: record.endTime,
                agentUserId: record.agentUserId || ''
            };
            recordCodeData = JSON.stringify(recordCodeData);
            this.$router.push(
                `/marketingCenter/inviteCode/usedCode/${recordCodeData}`
            );
        }
    }
}
</script>
<style lang="less" scoped>
.head-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.table-operator {
  margin-right: 30px;
}
</style>